h1, h2, h3, h4, a, p, span, div{
    font-family: "Poppins", sans-serif;
}
.apiImage{

    width: 20% !important;
}
.header .col-sm-12{
    text-align: center;
}
.header .col-sm-12 img{
    margin-top: 10px;
    width: 30%;
    margin-bottom: 30px;
}
.ageegroupp label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item{
    margin: 10px;
    border-radius: 0 !important;
    padding: 0 30px;
}
.prevBTn button{
    background: #031A5B !important;
    border: unset !important;
    font-size: 16px;
    padding: 10px 30px !important;
    color: #fff !important;
}
.nexxtBtn{
    text-align: right;
}
.nexxtBtn button{
    background: #3ECEB3 !important;
    border: unset !important;
    font-size: 16px;
    padding: 10px 30px !important;
}
.header .col-sm-12 p{
    text-align: center;
    color: #031A5B;
    font-size: 16px;
}
.modal-header{
    border: unset !important;
    
}
/* .header .col-sm-12 span{
    text-decoration: underline;
} */
.footer .col-sm-12{
    background-color: #031A5B;
    padding: 15px;
    text-align: center;
}
.footer .col-sm-12 span{
    color: #fff;
    font-size: 16px;
    padding-right: 20px;
}
.midsec {
    padding: 70px 50px;
    background: url("../Assets/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.midsec p.upper{
    text-align: center;
    font-size: 18px;
    color: #031A5B;
    width: 60%;
    margin: auto;
    margin-bottom: 50px;
}
.midsec p.lower{
    text-align: center;
    font-size: 18px;
    color: #031A5B;
    width: 40%;
    margin: auto;
    margin-top: 50px;
}
.midsec .col-sm-3{
    border: 2px solid #3ECEB3;
    text-align: center;
    background: #fff;
    padding: 35px 20px;
    width: 23%;
    margin-right: 20px;
    border-radius: 30px;
}
.midsec  h2{
    color:#3ECEB3;
    font-size: 42px;
}
.midsec .col-sm-3 p{
    color:#3ECEB3;
    font-size: 16px;
}
.animation button{
    border: unset;
    background: unset;
    width: 25%;
}
.animation button img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.animation .col-sm-6{
    text-align: center;
    position: relative;
    padding: 10%;
    z-index: 999;
}
.animation .col-sm-12{
    text-align: center;
}
.animation .col-sm-12 img{
    width: 50%;
}
.animation button{
    position: absolute;
}
.container-fluid.animation {
    padding-top: 0%;
}
button.left {
    right: 10%;
    top: 15%;
}
button.right {
    left: 21%;
    top: 15%;
}
.qnj {
    width: 50%;
    /* background-color: #3ECEB3; */
    padding: 20px 10px;
    border-radius: 20px;
    margin: auto;
    margin-top: -8.3%;
}
.qnj h2{
    font-size: 22px;
    font-weight: 700;
    color: #fff;
}
.qnj p{
    font-size: 18px;
    font-weight: 400 !important;
    color: #fff;
    margin: 0;
}
.col-sm-12.arrow {
    margin-top: -7%;
}
.arrow{
    padding-top: 1%;
    padding-bottom: 1%;
}
.arrow img{
    width: 3% !important;
    padding-bottom: 3%;
    margin-left: 3%;
}
:disabled{
    cursor: not-allowed;
}
.arrow p{
    width: 40%;
    margin: auto;
    text-align: center;
    font-size: 18px;
    color: #031A5B;
    padding-bottom: 3%;
}
.load1 svg {
    margin-bottom: 5%;
}
.fa.fa-chevron-down {
    color: #031A5B;
    font-size: 32px;
    margin-top: 70px;
    padding-bottom: 3%;
    margin-left: 2%;
}


svg {
    height: 900px !important;
    margin-top: -15%;
    position: relative;
    z-index: 9;
    margin-bottom: -9%;
}
.qnj {
    z-index: 99;
    position: relative;
}

.row.mobile{
    display: none;
}
.modal-content {
    border: 5px solid #031A5B;
}
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    z-index: 9999 !important;
}
.modal-header {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
}
.modal-dialog {
    max-width: 45% !important;
}
span.ant-radio-button.ant-radio-button-checked {
    background: #3ECEB3 !important;
}
.modal-dialog .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background: #3ECEB3 !important;
    border-color: #3ECEB3 !important;
}
.modal-dialog label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
    width: 30%;
    text-align: center;
}
.modal-dialog label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item:hover {
    background: #3ECEB3;
    color: #fff;
    border-color: #3ECEB3;
}
label.ant-form-item-required {
    font-size: 17px !important;
    font-weight: 600;
}
button.ant-btn {
    padding: 20px 60px !important;
    font-size: 18px ! IMPORTANT;
    background: #031A5B !important;
    color: #fff !important;
    width: 100% !important;
}
.ant-select-selector {
    padding: 10px ! IMPORTANT;
    height: 40px !important;
}
.ant-select .ant-select-arrow{
    top: 60% !important;
}
.col-sm-6.prevBTn, .col-sm-6.nexxtBtn {
    margin-top: 5%;
}
input#website {
    padding: 10px;
}
.col-sm-12.wellingss .ant-radio-button-wrapper {
    width: 30% !important;
}
.modal-header {
    padding-top: 5% !important;
    padding-bottom: 4%;
}
input#suggestions {
    padding: 10px;
}
span.ant-select-clear svg {
    height: auto !important;
}
.row.mybut {
    margin-left: 0;
}
button.offcanve-css {
    position: fixed;
    right: -2.5%;
    top: 50%;
    transform: rotate(-90deg);
    border: none;
    background: #3ECEB3;
    padding: 10px 30px;
    color: #fff;
    font-weight: 500;
    float: right;
    z-index: 999 !important;
    border-radius: 5px 5px 0px 0px;
}
@media only screen and (max-width: 1540px) {
    .arrow img {
        margin-left: 2%;
    }
    .header .col-sm-12 img {
        width: 20%;
    }
}

@media only screen and (max-width: 1440px) {

}

@media only screen and (max-width: 1366px) {
    button.left {
        right: 13.5%;
        top: 17%;
    }
    
    button.right {
        left: 26.5%;
        top: 18%;
    }    
}
@media only screen and (max-width: 1024px) {
    button.left {
        right: 18.3%;
        top: 30%;
    }
    button.right {
        left: 34%;
        top: 30%;
    } 
}

@media only screen and (max-width: 1025px) {
    .midsec .col-sm-3 {
        border: 2px solid #3ECEB3;
        text-align: center;
        background: #fff;
        padding: 20px 5px;
        width: 24%;
        margin-right: 9px;
        border-radius: 30px;
    }
    .animation button {
        border: unset;
        background: unset;
        width: 30%;
    }
    button.right {
        left: 31%;
        top: 22%;
    }
    button.left {
        right: 16.3%;
        top: 21%;
    }
}

@media only screen and (max-width: 820px) {
    .midsec .col-sm-3 {
        padding: 35px 5px;
        width: 47%;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 10px;
    }
    .header .col-sm-12 img {
        width: 35%;
    }
    .midsec h2 {
        font-size: 22px;
    }
    .midsec .col-sm-3 p {
        color: #3ECEB3;
        font-size: 16px;
    }
    .midsec p.upper {
        font-size: 14px;
    }
    .midsec p.lower {
        font-size: 14px;
    }
    .qnj h2 {
        font-size: 16px;
    }
    .qnj p {
        font-size: 13px;
    }
    .qnj{
        width: 60%;
    }
    .arrow p{
        width: 70%;
        font-size: 14px;
    }
    .animation button {
        border: unset;
        background: unset;
        width: 45%;
    }
    /* button.left {
        right: 10%;
    }
    button.right {
        left: 23%;
    } */
    button.left {
        right: 12.4%;
    
        top: 38%;
    }
    button.right {
        left: 30%;
        top: 38%;

    }
    
    
    .qnj {
        margin-top: -20%;
    }
    .col-sm-12.arrow {
        margin-top: -10%;
    }
}

@media only screen and (max-width: 540px) {
    .header .col-sm-12 img {
        width: 65%;
        margin-bottom: 10px;
    }
    .header .col-sm-12 p{
        font-size: 14px;
    }
    .midsec p.upper{
        width: 100%;
    }
    .midsec .col-sm-3{
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 10px ;
    }
    .animation .col-sm-12 img {
        width: 20%;
    }
    .animation .col-sm-6 {
        width: 50%;
    }
    .animation button {
        width: 55%;
    }
    button.left {
        right: 4%;
    }
    
    button.right {
        left: 19%;
    }
    
        
    .container-fluid.midsec {
        padding-bottom: 25%;
        margin-bottom: 0%;
    }
    .qnj {
        width: 100% !important;
        margin-top: -13% !important;
        margin-bottom: 4%;
    }
    .arrow p {
        width: 100%;
    }
    .arrow img {
        width: 7% !important;
        padding-bottom: 3%;
    }
    .qnj h2 {
        font-size: 14px;
    }
    .container-fluid.footer img {
        width: 45%;
    }
    .footer .col-sm-12 span{
        font-size: 12px;
    }
    svg {
        height: 485px !important;
        margin-top: -16%;
    }
    .container-fluid.animation {
        padding-top: 0%;
        margin-top: -8%;
    }
    button.right {
        left: 20% !IMPORTANT;
        top: 90% !important;
    }
    button.left {
        right: 11% !IMPORTANT;
        top: 90% !important;
    }
    .row.desktopp{
        display: none !important;
    }
    .mobile{
        display: block !important;
    }
    .swiper-wrapper {
        padding-bottom: 35px;
    }
    .swiper-pagination-bullet-active{
        background-color: #031A5B !important;
    }
    .midsec p.lower {
        text-align: center;
        font-size: 14px !important;
        color: #031A5B;
        width: 75% !important;
        margin: auto;
        margin-top: 50px;        
        padding-bottom: 15%;
    }
    .fa.fa-chevron-down {
        margin-top: 0 ;
     }
     .arrow p {
        padding-bottom: 10%;
    }
    .midsec {
        background-size: 105% 95%;
    }
    .load1 svg {
        margin-left: -5%;
    }
    .modal-dialog {
        max-width: 100% !important;
    }
    .fade.modal.show {
        padding: 0 !important;
    }
    .modal-header {
        text-align: center;
        font-size: 18px;
        justify-content: center;
     }
     .modal-dialog label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
        width: 97%;
        text-align: center;
        margin: 5px !important;
        margin-bottom: 10px !important;
    }
    .new-modal.modal-body {
        padding: 0;
    }
    .row.mybut .col-sm-6 {
        width: 48% !important;
        margin-left: 6px;
    }
    button.ant-btn {
        padding: 20px 0px !important;
        font-size: 16px ! IMPORTANT;
        background: #031A5B !important;
        color: #fff !important;
        width: 100% !important;
    }
    span.ant-select-clear svg {
        height: auto !important;
    }
    .row.mybut {
        margin-left: 0px;
    }
    .modal-dialog {
        top: 10%;
    }
    .col-sm-12.wellingss .ant-radio-button-wrapper {
        width: 97% !important;
    }
    .swal2-actions {
        display: block !important;
        width: 50%;
    }
    .swal2-actions button{
        width: 100% !important;
        background-color: #031A5B !important;
    }
    button.offcanve-css {
        position: fixed;
        right: -11.5%;
        top: 50%;
        transform: rotate(-90deg);
        border: none;
        background: #3ECEB3;
        padding: 10px 30px;
        color: #fff;
        font-weight: 500;
        float: right;
        z-index: 999 !important;
        border-radius: 5px 5px 0px 0px;
    }
}